import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CurrentState } from '../../state';
import { AuthState } from './state';

export const selectAuthState = createFeatureSelector<AuthState>('authFeature');

export const selectAuthLoading = createSelector(
  selectAuthState,
  (state) => state.state === CurrentState.Loading,
);

export const selectUser = createSelector(
  selectAuthState,
  (state) => {
    return state.user;
},
);
