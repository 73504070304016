import { EventItemsListState } from '../../../../gth/src/app/features/discover/game/list/state/state';
import { NavLayoutState } from '../../../../gth/src/app/shared/layouts/nav-bar-layout/state/state';
import { EventInfoState } from '../../../../gth-legacy/src/lib/components/event-info/state/state';
import { AuthState } from './features/auth/state';
import { JoinerState } from './features/joiners/state';
import { NotificationsState } from './features/notifications/state';
import { TeamsState } from './features/teams/state';
import { UnregisteredUserState } from './features/unregistered-user/state';
import { UserState } from './features/user/state';
import { EventState, InvoicesState } from './public-api';

export enum CurrentState {
    Loading = 'loading',
    Pending = 'pending',
    Success = 'success',
    Error = 'error',
}

export interface APP_STATE {
    eventItemListFeature: EventItemsListState,
    authFeature: AuthState,
    eventInfoState: EventInfoState,
    users: UserState,
    unregisterdUsers: UnregisteredUserState,
    event: EventState,
    joiners: JoinerState,
    navLayout: NavLayoutState,
    invoices: InvoicesState,
    teams: TeamsState,
    notifications: NotificationsState,
}


